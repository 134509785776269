<template>
	<div>
		<Hero image="../img/12.jpg" />
		<main>
			<div class="container">
				<Heading title="Mission & Vission" desc="Enlighten, Empower, Excel" />
				<div class="content">
					<div class="row">
						<div class="col-md-8">
							<Mission />
						</div>
						<div class="col-md-4">
							<MenuAbout />
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>

<script>
export default {
	data() {
		return {};
	},
	components: {
		Mission: () => import("@/components/AboutUs/MissionVision.vue"),
	},
};
</script>
